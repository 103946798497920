import React from 'react'

function Footer() {
    const today = new Date();

    return (
        <footer>
            <p>Copyright &copy; Craig Freeburg {today.getFullYear()}</p>
        </footer>
    )
}

export default Footer
